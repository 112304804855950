* {
  margin: 0;
}

.App{
  z-index: 2;
}

.leaflet-container {
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

#loader {
  position: absolute;
  top: 40%;
  left: 40%;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: 10;
}
