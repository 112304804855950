.Footer {
    font-family: arial;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 804;
    /*margin: auto;*/
  }
  
  .Footer p {
    margin: 0px 10px 0px 10px;
  }
  
  .Footer img {
    height: 20px;
    margin-left: 10px;
  }
  