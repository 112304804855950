.titulo {
  font-family: arial;
  font-weight: bold;
  font-size: 30px;
  color: #662992;
  position: absolute;
  top: 1px;
  right: 2%;
  z-index: 800;
}

.controles {
  padding: 3px;
  position: absolute;
  top: 70px;
  width: 400px;
  right: 2%;
  z-index: 800;
  color: white;
  font-weight: bold;
  background-color:#662992;
  font-family: arial;
  border: 2px solid black;
  border-radius: 1%;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.8);
}

.controles select{
  margin : 5px;
  width: 350px;
}

.limpaMapa{
  margin: 3px 0px;
}
